// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
library.add(faFacebook);
library.add(faBars);
dom.watch();

$('#main_menu-button').on( 'click', function(e) {
  e.preventDefault();
  e.stopPropagation();
  $('header.side-bar').toggleClass('navigation-open')

  $(document).one('click', function closeMenu (e){
    if($('header.side-bar').has(e.target).length === 0){
        $('header.side-bar').removeClass('navigation-open');
    } else {
        $(document).one('click', closeMenu);
    }
  });
});

var header_hero = $('#header_hero').offset();
var stickyOffset = -1;

if (header_hero != null) {
  stickyOffset = header_hero.top - $('.skos-navbar').height();
  $(window).on( 'scroll', function(){
    var sticky = $('.skos-navbar'),
        scroll = $(window).scrollTop();
  
    if (scroll >= stickyOffset) sticky.addClass(['bg-skos-primary', 'skos-divider', 'grunge-divider-trail']);
    else sticky.removeClass(['bg-skos-primary', 'skos-divider', 'grunge-divider-trail']);
  });
}
